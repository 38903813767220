import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import axios from "axios";

import { createProject, createTermin, Project, Termin } from "../type";
import { useSnackbarStore } from "./snackbarStore";
import { useProjectsStore } from "./projectsStore";
import { useTerminsStore } from "./terminsStore";

// USER

type userStore = {
  loggedIn: boolean;
  username: string;
  name: string;
  userId: number;
  fetchUserDetails: () => void;
  token: string;
  checkIfLoggedIn: () => void;
  logIn: (username: string, password: string) => void;
  logOut: () => void;
  loading: boolean;
};

export const useUserStore = create<userStore>()(
  persist(
    (set, get) => ({
      loggedIn: false,
      username: "Gost",
      name: "Gost",
      userId: -1,
      fetchUserDetails: async () => {
        if (get().loggedIn === false || get().userId === -1) {
          return;
        }
        await axios
          .get("https://hudniki.pythonanywhere.com/endpoints/userdetails/", {
            headers: {
              Authorization: `Token ${useUserStore.getState().token}`,
            },
          })
          .then((res) => {
            set({
              userId: res.data[0].user,
              name: res.data[0].name,
            });
          });
      },
      token: "",
      checkIfLoggedIn: async () => {
        // TODO!!!
        // function that checks if the credentials are still valid
        await axios
          .get("https://hudniki.pythonanywhere.com/endpoints/userdetails/", {
            headers: {
              Authorization: `Token ${get().token}`,
            },
          })
          .then((response) => {
            if (response.data.length === 0) {
              set({
                loggedIn: false,
                username: "Gost",
                name: "Gost",
              });
            } else {
              set({
                //loggedIn: true,
                //username: response.data.name,
                //name: response.data.name,
                //userId: response.data.user,
              });
            }
          })
          .catch((err) => {
            set({
              loggedIn: false,
              username: "Gost",
              name: "Gost",
            });
            console.log(err);
          });
      },
      logIn: async (username, password) => {
        set({ loading: true });
        await axios
          .post(
            "https://hudniki.pythonanywhere.com/auth/token/login",
            {
              password: password,
              username: username,
            },
            {
              headers: {
                "Access-Control-Allow-Credentials": "true",
              },
            },
          )
          .then((response) => {
            set({
              loggedIn: true,
              username: username,
              token: response.data.auth_token,
            });
            localStorage.setItem("token", response.data.auth_token);
            localStorage.setItem("user", username);
            useSnackbarStore
              .getState()
              .showSnackbar("success", "Vpis uspešen!");
            useProjectsStore.getState().fetchProjects();
          })
          .catch((error) => {
            console.log(error);
          });
        get().fetchUserDetails();
        set({ loading: false });

        // load project and termins and select the first project
        await useProjectsStore.getState().fetchProjects();
        if (useProjectsStore.getState().projects.length !== 0) {
          useTerminsStore.setState({
            project_id: useProjectsStore.getState().projects[0].id,
            project_name: useProjectsStore.getState().projects[0].name,
          });
        }
      },
      logOut: async () => {
        set({ loading: true });
        await axios
          .post(
            "https://hudniki.pythonanywhere.com/auth/token/logout",
            get().token,
            {
              headers: {
                Authorization: `Token ${get().token}`,
              },
            },
          )
          .then((response) => {
            set({
              loggedIn: false,
              username: "Gost",
              name: "Gost",
              userId: -1,
              token: "",
            });
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            useSnackbarStore
              .getState()
              .showSnackbar("warning", "Izpis uspešen!");
          })
          .catch((error) => {
            useSnackbarStore
              .getState()
              .showSnackbar(
                "danger",
                "Prišlo je do napake pri izpisevanju: " + error.message,
              );
            console.log(error);
          });
        set({ loading: false });
        useProjectsStore.getState().fetchProjects();
        useTerminsStore.getState().clearTerminsStore();
      },
      loading: false,
    }),
    {
      name: "user-storage",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
