import React from "react";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import {
  ButtonGroup,
  CssBaseline,
  CssVarsProvider,
  ListItemDecorator,
  Sheet,
  Snackbar,
  Tab,
  tabClasses,
  Table,
  TabList,
  TabPanel,
  Tabs,
} from "@mui/joy";
import Box from "@mui/joy/Box";
import {
  Add,
  Download,
  Edit,
  HomeMax,
  HouseOutlined,
  IosShare,
  IosShareRounded,
  Opacity,
  PublicOffSharp,
  PublicSharp,
  ShareOutlined,
  Wc,
} from "@mui/icons-material";
import Skeleton from "@mui/joy/Skeleton";
import { Delete } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { AnimatePresence, motion } from "framer-motion";

import Header from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import { TerminsList } from "./components/TerminsList";
import { useUserStore } from "./store/userStore";
import { individualTerminStore, useTerminsStore } from "./store/terminsStore";
import TerminsTable from "./components/TerminsTable";
import {
  useIndividualProjectStore,
  useProjectsStore,
} from "./store/projectsStore";
import { TerminModal } from "./components/modals/TerminModal";
import { ProjectModal } from "./components/modals/ProjectModal";
import SnackbarInfo from "./components/SnackbarInfo";
import { Welcome } from "./components/Welcome";
import RemoveModal from "./components/modals/RemoveModal";
import { Fade, TableCell, TableRow } from "@mui/material";
import { useShallow } from "zustand/react/shallow";
import { useCalendarStore } from "./store/calendarStore";
import MyCalendar from "./components/Calendar";
import { useSnackbarStore } from "./store/snackbarStore";
import IcalExportModal from "./components/modals/IcalExportModal";
import { useParticipationStore } from "./store/participations";
import { useOrganizersStore } from "./store/organizersStore";
import ParticipantsTable from "./components/ParticipantsTable";
import ProjectPage from "./components/ProjectPage";
import { URL } from "./globalConstants";

export default function Home() {
  const loggedIn = useUserStore((state) => state.loggedIn);
  // Retrieve User ID for enabling actions
  const userId = useUserStore((state) => state.userId);

  const navigate = useNavigate();
  const projects = useProjectsStore((state) => state.projects);
  const fetchProjects = useProjectsStore((state) => state.fetchProjects);
  const fetchTermins = useTerminsStore((state) => state.fetchTermins);

  const fetchOrganizers = useOrganizersStore((state) => state.fetchOrganizers);

  const {
    termins,
    owner,
    project_id,
    setProjectId,
    projectName,
    setProjectName,
    loading,
  } = useTerminsStore(
    useShallow((state) => ({
      termins: state.termins,
      owner: state.owner,
      project_id: state.project_id,
      setProjectId: state.setProjectId,
      projectName: state.project_name,
      setProjectName: state.setProjectName,
      loading: state.loading,
    })),
  );

  // set project_id and project_name, if in url
  const [searchParams] = useSearchParams();
  let url_id = Number(searchParams.get("id"));
  React.useEffect(() => {
    if (url_id && projectName === "project__name") {
      setProjectId(url_id);
      let project = projects.filter((project) => project.id === url_id);
      if (project.length > 0) {
        setProjectName(project[0].name);
      }
    }
  }, [projects]);

  React.useEffect(() => {
    fetchProjects();
  }, [userId]);

  React.useEffect(() => {
    fetchTermins();
    fetchOrganizers();
  }, [project_id]);

  // Termin and remove modal states
  const [openTerminModal, setOpenTerminModal] = React.useState<boolean>(false);
  const [openProjectModal, setOpenProjectModal] =
    React.useState<boolean>(false);

  // When logged in as guest, disable certain actions
  const [disableActions, setDisableActions] = React.useState<boolean>(true);

  React.useEffect(() => {
    let disable = userId !== owner;
    setDisableActions(disable);
  }, [project_id, owner, userId, loggedIn, projects]);

  return (
    <CssVarsProvider>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          minHeight: "100dvh",
          alignItems: "space-between",
        }}
      >
        <>
          <Header />
          <Sidebar setOpenProjectModal={setOpenProjectModal} />
          <Box
            sx={{
              px: { xs: 2, md: 6 },
              pt: {
                xs: "calc(25px + var(--Header-height))",
                sm: "calc(25px + var(--Header-height))",
                md: 6,
              },
              pb: { xs: 2, sm: 2, md: 3 },
              flex: 1,
              display: "flex",
              flexDirection: "column",
              minWidth: 0,
              height: "100dvh",
              gap: 1,
              overflow: "auto",
            }}
          >
            <>
              <Box
                component="main"
                className="MainContent"
                mb={1}
                sx={{
                  gap: 1,
                  minWidth: 0,
                  height: "100dvh",
                  justifyContent: "start",
                }}
              >
                <Tabs>
                  <TabList
                    disableUnderline
                    sx={{
                      p: 0.5,
                      mb: 3,
                      gap: 1,
                      boxShadow: "md",
                      borderRadius: "xl",
                      bgcolor: "background.level1",
                      [`& .${tabClasses.root}[aria-hover="true"`]: {
                        bgcolor: "background.surface",
                      },
                      [`& .${tabClasses.root}[aria-selected="true"]`]: {
                        boxShadow: "sm",
                        bgcolor: "background.surface",
                      },
                    }}
                  >
                    <Tab value={0} disableIndicator>
                      Osnovno
                    </Tab>
                    <Tab value={1} disableIndicator>
                      {" "}
                      Urnik{" "}
                    </Tab>
                    <Tab value={2} disableIndicator disabled={disableActions}>
                      {" "}
                      Udeleženci{" "}
                    </Tab>
                    <Tab value={3} disableIndicator>
                      {" "}
                      Koledar{" "}
                    </Tab>
                  </TabList>
                  <TabPanel
                    value={0}
                    sx={{
                      backgroundColor: "white",
                    }}
                  >
                    <ProjectPage
                      setOpenProjectModal={setOpenProjectModal}
                      disableActions={disableActions}
                    />
                  </TabPanel>

                  <TabPanel
                    keepMounted
                    key={1}
                    value={1}
                    sx={{
                      backgroundColor: "white",
                      px: 1,
                    }}
                  >
                    <Box>
                      {termins.length === 0 && !loading ? (
                        <Card
                          variant="outlined"
                          //className="collapseIn"
                          sx={{
                            mt: 5,
                          }}
                        >
                          <CardContent>
                            <Typography level="title-lg">
                              Ni terminov.
                            </Typography>
                            <Box
                              sx={{
                                mt: 2,
                                display: { xs: "none", sm: "flex" },
                                flexDirection: "row",
                              }}
                            >
                              <Button
                                disabled={disableActions}
                                color="primary"
                                startDecorator={<Add />}
                                size="sm"
                                onClick={() => {
                                  setOpenTerminModal(true);
                                }}
                                sx={{}}
                              >
                                Dodaj termin
                              </Button>
                            </Box>
                          </CardContent>
                        </Card>
                      ) : (
                        <></>
                      )}

                      <TerminsList
                        setOpenModal={setOpenTerminModal}
                        setOpenTerminModal={setOpenTerminModal}
                      />
                      <TerminsTable setOpenModal={setOpenTerminModal} />
                    </Box>
                  </TabPanel>
                  <TabPanel
                    keepMounted
                    key={2}
                    sx={{
                      backgroundColor: "white",
                      px: 1,
                    }}
                    value={2}
                  >
                    <Box>
                      <ParticipantsTable />
                    </Box>
                  </TabPanel>
                  <TabPanel
                    keepMounted
                    value={3}
                    sx={{
                      backgroundColor: "white",
                      px: 1,
                    }}
                  >
                    <Box sx={{ boxShadow: "sm" }}>
                      <MyCalendar />
                    </Box>
                  </TabPanel>
                </Tabs>
                <Box
                  sx={{
                    bottom: 0,
                    display: "flex",
                    mt: 2,
                    mb: 2,
                    gap: 1,
                    flexDirection: { xs: "row", sm: "row" },
                    alignItems: { xs: "start", sm: "center" },
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                ></Box>
              </Box>
            </>
          </Box>
        </>
        <TerminModal open={openTerminModal} setOpen={setOpenTerminModal} />
        <ProjectModal open={openProjectModal} setOpen={setOpenProjectModal} />
        <SnackbarInfo />
      </Box>
    </CssVarsProvider>
  );
}
