import { create } from "zustand";
import axios from "axios";
import { useSnackbarStore } from "./snackbarStore";
import { useTerminsStore } from "./terminsStore";

export type Organizer = {
  id: number;
  name: string;
  email: string;
};

type organizersStore = {
  organizers: Organizer[];
  fetchOrganizers: () => void;
};

export const useOrganizersStore = create<organizersStore>()((set, get) => ({
  organizers: [],
  fetchOrganizers: async () => {
    set({ organizers: [] });
    const project_id = useTerminsStore.getState().project_id;
    if (project_id === -1) {
      return;
    }
    await axios
      .get(
        `https://hudniki.pythonanywhere.com/endpoints/projectOrganizers/?project=` +
          project_id,
      )
      .then((response) => {
        set({
          organizers: response.data,
        });
      })
      .catch((error) => {
        useSnackbarStore
          .getState()
          .showSnackbar(
            "danger",
            "Napaka pri pridobivanju organizatorjev: " + error.message,
          );
      });
  },
}));
